import { Link } from 'gatsby';
import React from 'react';

import ContentSection from '../components/content-section';
import Layout from '../components/layout';
import MetaData from '../components/meta-data';

const HaftungsausschlussPage = (props) => {
  const title = 'Haftungsausschluss';

  return (
    <Layout path={props.location.pathname}>
      <MetaData
        title={title}
        description="Der Haftungsausschluss der Webseite von Coiffeur Cocoon."
      />

      <ContentSection>
        <h1>{title}</h1>

        <h2 className="mt-30">
          <small>1. Inhalt des Onlineangebotes</small>
        </h2>
        <p>
          Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit,
          Vollständigkeit oder Qualität der bereitgestellten Informationen.
          Haftungsansprüche gegen den Autoren, welche sich auf Schäden
          materieller oder ideeller Art beziehen, die durch die Nutzung oder
          Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung
          fehlerhafter und unvollständiger Informationen verursacht wurden, sind
          grundsätzlich ausgeschlossen, sofern seitens des Autors kein
          nachweislich vorsätzliches oder grob fahrlässiges Verschulden
          vorliegt. Alle Angebote sind freibleibend und unverbindlich. Der Autor
          behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte
          Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu
          löschen oder die Veröffentlichung zeitweise oder endgültig
          einzustellen.
        </p>

        <h2 className="mt-30">
          <small>2. Verweise und Links</small>
        </h2>
        <p>
          Bei direkten oder indirekten Verweisen auf fremde Webseiten
          («Hyperlinks»), die ausserhalb des Verantwortungsbereiches des Autors
          liegen, würde eine Haftungsverpflichtung ausschliesslich in dem Fall
          in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es
          ihm technisch möglich und zumutbar wäre, die Nutzung im Falle
          rechtswidriger Inhalte zu verhindern. Der Autor erklärt hiermit
          ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen
          Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die
          aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft
          der gelinkten/verknüpften Seiten hat der Autor keinerlei Einfluss.
          Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten
          aller gelinkten/verknüpften Seiten, die nach der Linksetzung verändert
          wurden. Diese Feststellung gilt für alle innerhalb des eigenen
          Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge
          in vom Autor eingerichteten Gästebüchern, Diskussionsforen und
          Mailinglisten. Für illegale, fehlerhafte oder unvollständige Inhalte
          und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung
          solcherart dargebotener Informationen entstehen, haftet allein der
          Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der
          über Links auf die jeweilige Veröffentlichung lediglich verweist.
        </p>

        <h2 className="mt-30">
          <small>3. Urheber- und Kennzeichenrecht</small>
        </h2>
        <p>
          Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der
          verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu
          beachten, von ihm selbst erstellte Grafiken, Tondokumente,
          Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken,
          Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb
          des Internetangebotes genannten und ggf. durch Dritte geschützten
          Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen
          des jeweils gültigen Kennzeichenrechts und den Besitzrechten der
          jeweiligen eingetragenen Eigentümer. Allein aufgrund der blossen
          Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht
          durch Rechte Dritter geschützt sind! Das Copyright für
          veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim
          Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher
          Grafiken, Tondokumente, Videosequenzen und Texte in anderen
          elektronischen oder gedruckten Publikationen ist ohne ausdrückliche
          Zustimmung des Autors nicht gestattet.
        </p>

        <h2 className="mt-30">
          <small>4. Datenschutz</small>
        </h2>
        <p>
          Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe
          persönlicher oder geschäftlicher Daten (E-Mail-Adressen, Namen,
          Anschriften) besteht, so erfolgt die Preisgabe dieser Daten auf
          ausdrücklich freiwilliger Basis. Die Nutzung der im Rahmen des
          Impressums oder vergleichbarer Angaben veröffentlichten Kontaktdaten
          wie Postanschriften, Telefon- und Faxnummern sowie E-Mail-Adressen
          durch Dritte zur Übersendung von nicht ausdrücklich angeforderten
          Informationen ist nicht gestattet. Rechtliche Schritte gegen die
          Versender von sogenannten Spam-Mails bei Verstössen gegen dieses
          Verbot sind ausdrücklich vorbehalten.
        </p>
        <p>
          Weitere Informationen zum Datenschutz sind in der{' '}
          <Link to="/datenschutz/">Datenschutzerklärung</Link> zu finden.
        </p>

        <h2 className="mt-30">
          <small>5. Rechtswirksamkeit dieses Haftungsausschlusses</small>
        </h2>
        <p>
          Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
          betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile
          oder einzelne Formulierungen dieses Textes der geltenden Rechtslage
          nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben
          die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit
          davon unberührt.
        </p>
      </ContentSection>
    </Layout>
  );
};

export default HaftungsausschlussPage;
